// App.js

import React, { Component, useState } from "react";
import ReactDOM from "react-dom";
//import Pagination from "react-js-pagination";

import Web3 from 'web3';
import { simpleStorageAbi } from './abis';
import Tabs from 'react-bootstrap/Tabs';

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

const web3 = new Web3(Web3.givenProvider);

//const contractAddr = '0xb4135b51F2D97C839c051E3c934100a1f1415a87';
const contractAddr = '0x2B4624997B3778FD05fF8eB4A1EDa15F56f4Dbe5';
const SimpleContract = new web3.eth.Contract(simpleStorageAbi, contractAddr);
let myVar;
var vipers=[];

const handleSet = async (e) => {
  if (typeof web3 === 'undefined') return;
  e.preventDefault();    
  //const accounts = await window.ethereum.enable();
  //const account = accounts[0];
  //const gas = await SimpleContract.methods.buyViper().send().estimateGas({gas:21000});
  const result = await SimpleContract.methods.buyViper().send({
	from: web3.eth.accounts.givenProvider.selectedAddress,
    value: web3.utils.toWei('0.02', 'ether'),
	gasPrice: '200000',
  })
  console.log(result);
};

  async function getVipers() {
  if (typeof web3 === 'undefined') return;
  const accounts = await window.ethereum.enable();
  let receipt = await SimpleContract.methods.ownedVipers().call({from: web3.eth.accounts.givenProvider.selectedAddress});
  console.log(web3.eth.accounts.givenProvider.selectedAddress);
  for (let i = 0; i < receipt.length; i += 1) {
	SimpleContract.methods.getViperDetails(receipt[i]).call({from: web3.eth.accounts.givenProvider.selectedAddress,
	}).then((viper) => {
		//Controllo che non sia già stato inserito precedentemente
		let temp=vipers.find(item => item.id === viper[0]);
		if (typeof temp == 'undefined') {				  
			vipers.push({
			  id: viper[0],
			  genes: viper[1],
			  matron: viper[2],
			  sire: viper[3],
			  img: "https://dapp.gattosiberiano.eu/images/small/2160.png",
			  });
		}
		}).catch((err) => {
			console.log(err, 'err');
			});
	}
  console.log(vipers);
  };


class App extends React.Component {
	
  constructor(props) {
		super(props);
		getVipers();
		this.state = {
			vipers: vipers
		}
		this.refreshViper = this.refreshViper.bind(this);
		this.delVipers = this.delVipers.bind(this);
  }

   drawVipers(){	
    let item =(
      <div>
	  {vipers.map(viper => 
		<span key={viper.id} className="carta">
		  <div className="image">
		  </div>
		  <div className="content">
			<div class="Creatura">#{viper.id}</div>
			<div class="Foto"><img src={viper.img} alt="Creatura"></img></div>
			<div class="Vita"><img src="https://dapp.gattosiberiano.eu/images/heart.png" alt="Vita"></img>{viper.genes}</div>
			<div class="Attacco"><img src="https://dapp.gattosiberiano.eu/images/sword.png" alt="Attacco"></img>{viper.matron}</div>
			<div class="Difesa"><img src="https://dapp.gattosiberiano.eu/images/shield.png" alt="Difesa"></img>{viper.sire}</div>
		  </div>
		</span>
		
	  )}
	  </div>
	);
	ReactDOM.render(item, document.getElementById("lista"));
  }

  refreshViper(){
	getVipers();
	this.setState({
      vipers: vipers,
	});
    this.drawVipers();
	clearTimeout(myVar);
	
  }

  delVipers() {
	if (vipers.length>0) {
	  //let temp=vipers.find(item => item.id === vipers[vipers.length-1]);
	  vipers.pop();
	  this.refreshViper();
	  console.log('1 Viper deleted');
	}	  
  }

 

   render() {
    return (
<div className="App" >

<Tabs defaultActiveKey="myvipers" id="uncontrolled-tab-example">
  <Tabs eventKey="home" title="Home">

  </Tabs>
  <Tabs eventKey="myvipers" title="My Vipers">
    <div classname="bottoni">
 		<button onClick={handleSet} type="button" > 
          Buy 1 Viper 
        </button>    
		<button onClick={this.delVipers} type="button" >
		  Del last Viper 
        </button>
		<button onClick={this.drawVipers} type="button" > 
          Refresh Viper 
        </button>
	</div>
	<hr/>		
	<div id="lista">
		<br/><br/><br/>
		<button onClick={this.refreshViper} type="button" > 
          Load my Vipers
        </button>  
	</div>
	
  </Tabs>
  <Tabs eventKey="contact" title="Contact" disabled>
  </Tabs>
</Tabs>

</div>
    )
  }  
}

export default App;

